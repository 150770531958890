import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

import Seo from "components/shared/Seo";
import BannerMain from "components/banners/BannerMain";
import GalleryGrid from "components/GalleryGrid";
import Quote from "components/Quote";
import Process from "components/Process";
import MainLayout from "layouts/MainLayout";

class IndexPage extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wpgraphql {
              page(id: "/lp-5/", idType: URI) {
                PageIndex {
                  bannerFormHeading
                  bannerFormConsent
                  bannerFormConsentLength
                  bannerBackgroundColor
                  bannerImage {
                    mediaItemUrl
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 1920, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  bannerImageMobile {
                    mediaItemUrl
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 1200, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }

                  aboutHeading
                  aboutText

                  portfolioHeading
                  portfolioText
                  portfolioGallery {
                    portfolioGalleryImage {
                      mediaItemUrl
                      imageFile {
                        childImageSharp {
                          fluid(maxWidth: 1000, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }

                  quoteText
                  quoteAuthor
                  phone
                }
              }
            }
          }
        `}
        render={(data) => {
          function formattier(x) {
            const y = x.replace("(", "<strong>").replace(")", "</strong>");

            return y;
          }

          const page = data.wpgraphql.page.PageIndex;

          const banner = {
            image: page.bannerImage.imageFile.childImageSharp.fluid,
            imageMobile: page.bannerImageMobile.imageFile.childImageSharp.fluid,
            heading: page.bannerFormHeading
              ? formattier(page.bannerFormHeading)
              : "Porozmawiaj z naszym doradcą bez zobowiązań:",
            consent: page.bannerFormConsent
              ? formattier(page.bannerFormConsent)
              : 'Wyrażam zgodę na przesłanie za pośrednictwem telekomunikacyjnych urządzeń końcowych oraz na przekazywanie za pomocą połączeń telefonicznych, informacji handlowych dotyczących produktów i usług oferowanych przez Larsson Nobel Sp. z o.o., przy wykorzystaniu adresu e-mail oraz numeru telefonu, podanych przeze mnie w formularzu kontaktowym. Niniejsza zgoda jest całkowicie dobrowolna, brak jej wyrażenia oznacza, że Spółka może wykorzystać podane dane tylko na potrzeby jednorazowego kontaktu. Zgoda może zostać wycofana w każdym czasie poprzez przesłanie oświadczenia na adres iod@larssonnobel.pl. Administratorem danych osobowych podawanych na potrzeby kontaktu i przesyłania informacji handlowych jest Larsson Nobel Sp. z o.o. Prosimy o zapoznanie się z pełną treścią informacji o przetwarzaniu danych osobowych, która znajduje się w polityce prywatności, aby uzyskać dostęp <a target="_blank" href="https://larssonnobel.pl/wp-content/uploads/2021/05/21_05_polityka-prywatnosci.pdf">kliknij tutaj</a>',
          };

          const quote = {
            content: page.quoteText,
            author: page.quoteAuthor,
            phone: page.phone,
          };

          const portfolio = {
            title: page.portfolioHeading,
            text: page.portfolioText,
            images: page.portfolioGallery.map(
              ({ portfolioGalleryImage }) =>
                portfolioGalleryImage.imageFile.childImageSharp.fluid
            ),
          };

          const about = {
            heading: page.aboutHeading,
            text: page.aboutText,
          };

          return (
            <MainLayout themeSecondaryColor={page.bannerBackgroundColor}>
              <Seo title="Strona główna" />

              <BannerMain
                image={banner.image}
                imageMobile={banner.imageMobile}
                heading={banner.heading}
                consent={banner.consent}
              />
              <Process heading={about.heading} text={about.text} />

              <GalleryGrid
                heading={portfolio.title}
                text={portfolio.text}
                images={portfolio.images}
              />
              <Quote
                content={quote.content}
                author={quote.author}
                phone={quote.phone}
              />
            </MainLayout>
          );
        }}
      ></StaticQuery>
    );
  }
}

export default IndexPage;
